import type { DefineComponent } from 'vue'
import IconDeviceMobile from '@/assets/images/device-mobile.svg'
import IconInstagram from '@/assets/images/instagram.svg'
import IconFacebook from '@/assets/images/facebook.svg'
import IconTikTok from '@/assets/images/tiktok.svg'
import IconTwitter from '@/assets/images/twitter.svg'
import IconYouTube from '@/assets/images/youtube.svg'
import IconPinterest from '@/assets/images/pinterest.svg'
import IconCategoryInstagram from '@/assets/images/category-instagram.svg'
import IconCategoryFacebook from '@/assets/images/category-facebook.svg'
import IconCategoryTikTok from '@/assets/images/category-tiktok.svg'
import IconCategoryTwitter from '@/assets/images/category-twitter.svg'
import IconCategoryYoutube from '@/assets/images/category-youtube.svg'
import IconCategoryPinterest from '@/assets/images/category-pinterest.svg'
import IconColorfulInstagram from '@/assets/images/colorful-instagram.svg'
import IconColorfulFacebook from '@/assets/images/colorful-facebook.svg'
import IconColorfulTikTok from '@/assets/images/colorful-tiktok.svg'
import IconColorfulTwitter from '@/assets/images/colorful-twitter.svg'
import IconColorfulYoutube from '@/assets/images/colorful-youtube.svg'
import IconColorfulPinterest from '@/assets/images/colorful-pinterest.svg'
import IconColorfulTwitch from '@/assets/images/colorful-twitch.svg'
import IconColorfulSnapchat from '@/assets/images/colorful-snapchat.svg'
import IconColorfulWebsite from '@/assets/images/colorful-website.svg'
import IconColorfulOther from '@/assets/images/colorful-other.svg'
import IconEnvelopeSimple from '@/assets/images/envelope-simple.svg'
import IconVideoCamera from '@/assets/images/video-camera.svg'
import IconTelevision from '@/assets/images/television.svg'
import IconShoppingCart from '@/assets/images/shopping-cart.svg'
import IconWarning from '@/assets/images/warning.svg'
import IconCheckCircle from '@/assets/images/check-circle.svg'
import IconBeauty from '@/assets/images/interests/beauty.svg'
import IconDIY from '@/assets/images/interests/diy.svg'
import IconLeisure from '@/assets/images/interests/leisure.svg'
import IconFamily from '@/assets/images/interests/family.svg'
import IconFashion from '@/assets/images/interests/fashion.svg'
import IconFitness from '@/assets/images/interests/fitness.svg'
import IconFood from '@/assets/images/interests/food.svg'
import IconLifestyle from '@/assets/images/interests/lifestyle.svg'
import IconPets from '@/assets/images/interests/pets.svg'
import IconTravel from '@/assets/images/interests/travel.svg'
import IconPhotography from '@/assets/images/interests/photography.svg'
import IconEntertainment from '@/assets/images/interests/entertainment.svg'
import IconStar from '@/assets/images/star.svg'
import IconArchiveBox from '@/assets/images/archive-box.svg'
import IconCards from '@/assets/images/cards.svg'
import IconChalkboardTeacher from '@/assets/images/chalkboard-teacher.svg'
import IconCube from '@/assets/images/cube.svg'
import IconMonitorPlay from '@/assets/images/monitor-play.svg'
import IconPersonSimpleRun from '@/assets/images/person-simple-run.svg'
import IconPhone from '@/assets/images/phone.svg'
import IconPlayCircle from '@/assets/images/play-circle.svg'
import IconShirt from '@/assets/images/shirt.svg'
import IconStudent from '@/assets/images/student.svg'
import IconUserList from '@/assets/images/user-list.svg'
import IconMapPin from '@/assets/images/map-pin.svg'
import IconClock from '@/assets/images/clock.svg'
import IconQueue from '@/assets/images/queue.svg'
import IconFolderOpen from '@/assets/images/folder-open.svg'
import IconChatCircle from '@/assets/images/chat-circle.svg'
import IconCurrencyCircleDollar from '@/assets/images/currency-circle-dollar.svg'
import IconChats from '@/assets/images/chats.svg'
import IconLockKey from '@/assets/images/lock-key.svg'
import IconBimbino from '@/assets/images/logos/bimbino.svg'
import IconChicco from '@/assets/images/logos/chicco.svg'
import IconClinique from '@/assets/images/logos/clinique.svg'
import IconCrocs from '@/assets/images/logos/crocs.svg'
import IconEsteeLauder from '@/assets/images/logos/estee-lauder.svg'
import IconFox from '@/assets/images/logos/fox.svg'
import IconKinder from '@/assets/images/logos/kinder.svg'
import IconSchweppes from '@/assets/images/logos/schweppes.svg'
import IconZillow from '@/assets/images/logos/zillow.svg'

import type { IconName } from '@/types/icon-name'

export default function (iconName: IconName | string): DefineComponent {
  const icons: Record<string, DefineComponent> = {
    IconDeviceMobile,
    IconInstagram,
    IconFacebook,
    IconTikTok,
    IconTwitter,
    IconYouTube,
    IconPinterest,
    IconCategoryInstagram,
    IconCategoryFacebook,
    IconCategoryTikTok,
    IconCategoryTwitter,
    IconCategoryYoutube,
    IconCategoryPinterest,
    IconColorfulInstagram,
    IconColorfulFacebook,
    IconColorfulTikTok,
    IconColorfulTwitter,
    IconColorfulYoutube,
    IconColorfulPinterest,
    IconColorfulTwitch,
    IconColorfulSnapchat,
    IconColorfulWebsite,
    IconColorfulOther,
    IconEnvelopeSimple,
    IconVideoCamera,
    IconTelevision,
    IconShoppingCart,
    IconWarning,
    IconCheckCircle,
    IconBeauty,
    IconDIY,
    IconLeisure,
    IconFamily,
    IconFashion,
    IconFitness,
    IconFood,
    IconLifestyle,
    IconPets,
    IconTravel,
    IconPhotography,
    IconEntertainment,
    IconStar,
    IconArchiveBox,
    IconCards,
    IconChalkboardTeacher,
    IconCube,
    IconMonitorPlay,
    IconPersonSimpleRun,
    IconPhone,
    IconPlayCircle,
    IconShirt,
    IconStudent,
    IconUserList,
    IconMapPin,
    IconClock,
    IconQueue,
    IconFolderOpen,
    IconChatCircle,
    IconCurrencyCircleDollar,
    IconChats,
    IconLockKey,
    IconBimbino,
    IconChicco,
    IconClinique,
    IconCrocs,
    IconEsteeLauder,
    IconFox,
    IconKinder,
    IconSchweppes,
    IconZillow,
  }

  return icons[iconName]
}
